import styled from "@emotion/styled";

const FadingDivider = styled.div`
  margin-top: 0;
  margin-bottom: 1rem;
  width: 100%;
  height: 1px;

  background-color: ${(props) => props.theme.color.background.grey.light};
  background-image: linear-gradient(
    left,
    white 0%,
    ${(props) => props.theme.color.background.grey.light} 50%,
    white 100%
  );
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0, white),
    color-stop(0.5, ${(props) => props.theme.color.background.grey.light}),
    color-stop(1, white)
  );
`;

export default FadingDivider;
