import { useRouter } from "next/router";
import React from "react";
import SEO from "../components/seo";
import ErrorHandler from "../containers/error-handler";
import { getPageTitle } from "../libs/seo";
import { FetchError } from "../types/error";

const NotFoundPage: React.FC = () => {
  const router = useRouter();
  const error = new FetchError(
    404,
    `Page not found: ${router.pathname}\n${JSON.stringify(router.query)}`
  );
  return (
    <>
      <SEO title={getPageTitle("404")} noIndex />
      <ErrorHandler error={error} />
    </>
  );
};

export default NotFoundPage;
