import styled from "@emotion/styled";
import { TrackButton, TrackButtonProps } from "./common";

const OutlinedButton = styled(({ ...props }) => (
  <TrackButton variant="outlined" {...props} />
))<TrackButtonProps>`
  border-radius: 1rem;
  width: 100%;
  flex: 1;
  font-family: ${(props) => props.theme.fontFamily.system};
  color: ${(props) => props.theme.color.text.dark};
`;

export default OutlinedButton;
