import Button from "@material-ui/core/Button";
import { track } from "../../libs/analytics";

export interface TrackButtonProps extends React.ComponentProps<typeof Button> {
  trackingName?: string;
  component?: any;
}

export const TrackButton: React.FC<TrackButtonProps> = ({
  children,
  trackingName,
  variant,
  onClick,
  ...props
}) => (
  <Button
    variant={variant}
    onClick={(e) => {
      if (trackingName) track(`${trackingName} Button Clicked`);
      onClick?.(e);
    }}
    {...props}
  >
    {children}
  </Button>
);
